import { TextInput } from "pepsico-ds"; // Assuming you are using pepsico-ds like the previous component
import PropTypes from "prop-types";
import "./timeRangeInput.scss"; // Your custom styles

export default function TimeRangeInputField({
  time,
  updateTime,
  disabled,
  errors,
  required,
  visible,
}) {
  const handleInputChange = (field, value) => {
    const updatedTime = {
      ...time,
      [field]: value === "" || value < 0 ? "" : parseInt(value, 10),
    };
    updateTime(updatedTime);
  };

  const handleKeyDown = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
  return visible ? (
    <div className="time-range-input">
      <TextInput
        label="Days"
        type="number"
        value={time.day === 0 ? "" : time.day}
        disabled={disabled}
        required={required}
        error={errors}
        onUpdate={(val) => handleInputChange("day", val)}
        onKeyDown={handleKeyDown}
      />
      <TextInput
        label="Hours"
        type="number"
        value={time.hour === 0 ? "" : time.hour}
        disabled={disabled}
        required={required}
        error={errors}
        onUpdate={(val) => handleInputChange("hour", val)}
        onKeyDown={handleKeyDown}
      />
      <TextInput
        label="Minutes"
        type="number"
        value={time.minute === 0 ? "" : time.minute}
        disabled={disabled}
        required={required}
        error={errors}
        onUpdate={(val) => handleInputChange("minute", val)}
        onKeyDown={handleKeyDown}
      />
    </div>
  ) : null;
}

TimeRangeInputField.propTypes = {
  time: PropTypes.shape({
    day: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
  }).isRequired,
  updateTime: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.string,
  required: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

TimeRangeInputField.defaultProps = {
  disabled: false,
  errors: "",
  required: false,
};
